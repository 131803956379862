import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';

const App = () => {
    return (
        <Layout>
            <Routes>
                {AppRoutes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        index={route.index}
                        element={route.element}
                    />
                ))}
            </Routes>
        </Layout>
    );
};

export default App;
