import React, { useState, useEffect } from "react";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupText } from "reactstrap";
import logo from "../assets/mhe_logo.png";
import "../css/NavMenu.css";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Search, ArrowRight } from 'react-bootstrap-icons';
import IconInput from '../components/Shared/IconInput';

const NavMenu = () => {
    const navigate = useNavigate();

    const bearerToken = localStorage.getItem('bearerToken');
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getCategories();
        getProducts();
    }, []);

    const getProducts = async () => {
        try {
            const response = await axios.get('/Website/getProducts', {
                headers: { Authorization: `Bearer ${bearerToken}` },
            });
            setProducts(response.data);
           // setContent(response.data);
        } catch (error) {
            if (bearerToken == null) {
                console.error('Error fetching product list due to null token.');
            } else {
                console.error('Error fetching product list:', error);
            }
            
        }
    };

    const getCategories = async () => {
        try {
            const response = await axios.get('/Website/GetCategories', {
                headers: { Authorization: `Bearer ${bearerToken}` },
            });
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const searchProducts = (text) => {
        const searchParam = ["proD_NAME"];
        let result;

        const filteredByText = products.filter((item) =>
            searchParam.some((param) =>
                item[param].toLowerCase().includes(text.toLowerCase())
            )
        );

        //if (filterBy === 0) {
        //    result = filteredByText;
        //} else {
        //    result = filteredByText.filter((item) =>
        //        item.caT_ID.includes(filterBy)
        //    );
        //}

        //setFilterList(result);
        //setContent(result);
    };

    const navToPage = (page, category) => {
        console.log(page);

        const sortedProducts = [...products].sort((a, b) => {
            const aHasNx = a.proD_NAME.toLowerCase().includes("nx") ? 0 : 1;
            const bHasNx = b.proD_NAME.toLowerCase().includes("nx") ? 0 : 1;

            if (aHasNx !== bHasNx) {
                return aHasNx - bHasNx; // "nx" names first
            }

            return a.proD_NAME.localeCompare(b.proD_NAME); // Regular alphabetical sorting
        });


        navigate(page, { state: { cat: categories, products: sortedProducts, selCat: category } });
    }



    return (
        <Navbar className="navbar-dark bg-black nav-container">
            <div className="top-section">
                <NavbarBrand href="/">
                    <img src={logo} alt="Logo" className="logo" />
                </NavbarBrand>

                {/*<InputGroup className="nav-ContainerOverride">*/}
                {/*    <InputGroupText className="nav-IconContainerOverride">*/}
                {/*        {<Search />}*/}
                {/*    </InputGroupText>*/}
                {/*    <Input*/}
                {/*        className="nav-inputOverride"*/}
                {/*        placeholder="Search..."*/}
                        
                {/*        onChange={(e) => searchProducts(e.target.value)}*/}
                {/*        name="NavSearchBar"*/}
                {/*        bsSize="lg"*/}
                {/*        type="text"*/}
                {/*        autoComplete="search"*/}
                {/*    />*/}
                {/*</InputGroup>*/}

            </div>
            <div className="bottom-section">
                <Nav className="nav-links">
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            Products
                        </DropdownToggle>
                        <DropdownMenu>
                            {categories.map(x => (<DropdownItem key={x.caT_ID} onClick={() => navToPage('/Products', x.caT_ID)}>{x.caT_NAME}</DropdownItem>))}
                            <div style={{ padding:'10px' }}></div>
                            <DropdownItem className="dropdown-item-explore" onClick={() => navToPage('/Products',0)}>Explore all products <ArrowRight className="arrowStyle" /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                        <NavLink href="/about" className="nav-link">About Us</NavLink>
                    </NavItem>
                </Nav>
            </div>
        </Navbar>
    );
};

export default NavMenu;
