import React, { useState, useEffect } from 'react';
import HomeImage from '../assets/homeImage.jpg';
import '../css/main.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
    const [imageDataList, setImageDataList] = useState([]);
    const [products, setProducts] = useState([]);
    const [content, setContent] = useState([]);
    const [imageDirectory, setImageDirectory] = useState('');
    const [docsDirectory, setDocsDirectory] = useState('');

    const bearerToken = localStorage.getItem('bearerToken');
    const navigate = useNavigate();

    useEffect(() => {
        const authenticateAndCheckImages = async () => {
            try {
                await authUser();
                await runImageCheck();
            } catch (error) {
                console.error('Error during authentication or image check:', error);
            }
        };
        getProductsList();
        GetDirectories();

        authenticateAndCheckImages();
    }, []);

    const authUser = async () => {
        try {
            const response = await axios.get("/Website/MHEWebsiteLogin", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    const getProductsList = async () => {
        try {
            const response = await axios.get('/Website/getProducts', {
                headers: { Authorization: `Bearer ${bearerToken}` },
            });
            setProducts(response.data);
            setContent(response.data);
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };

    const runImageCheck = async () => {
        try {
            const response = await axios.get("/Website/CheckAndCopyBlobStorageToWebsite", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
        } catch (error) {
            console.error('Image check error:', error);
        }
    };

    const GetDirectories = async () => {
        try {
            const response = await axios.get("/Website/GetDirectories", {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            setImageDirectory(response.data.imgDirectory);
            setDocsDirectory(response.data.docsDirectory)
            return response.data;


        } catch (error) {
            console.error(error);
        }
    };
   
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 3;

    const handleNext = () => {
        if (startIndex + itemsPerPage < products.length) {
            setStartIndex(startIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

    const featureNav = {
        color: 'blue'
    }

    const navToPage = (page, category) => {
        console.log(page);

        const sortedProducts = [...products].sort((a, b) => {
            const aHasNx = a.proD_NAME.toLowerCase().includes("nx") ? 0 : 1;
            const bHasNx = b.proD_NAME.toLowerCase().includes("nx") ? 0 : 1;

            if (aHasNx !== bHasNx) {
                return aHasNx - bHasNx; // "nx" names first
            }

            return a.proD_NAME.localeCompare(b.proD_NAME); // Regular alphabetical sorting
        });


       // navigate(page, { state: { cat: categories, products: sortedProducts, selCat: category } });
    }

    const genImagePath = (imgPath) => {

        return + imageDirectory + '/' + imgPath;
    }

    return (
        <div className="fullscreen-image">
            <img src={HomeImage} alt="Home"  />
       
            <div className="featured-products">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                    }}
                >
                    <h1 style={{ marginLeft: "10%" }}>Featured Products</h1>
                    <div style={{ marginRight: "10%" }}>
                        <button className="arrow left" onClick={handlePrev}>
                            &#8592;
                        </button>
                        <button className="arrow right" onClick={handleNext}>
                            &#8594;
                        </button>
                    </div>
                </div>

                <div className="carousel">
                    <div className="product-container">
                        {products
                            .sort((a, b) => {
                                const aHasNX = a.proD_NAME.includes("NX") ? 1 : 0;
                                const bHasNX = b.proD_NAME.includes("NX") ? 1 : 0;
                                return bHasNX - aHasNX;
                            })
                            .slice(startIndex, startIndex + itemsPerPage)
                            .map((product, index) => (
                                <Link
                                    key={product.id || `product-${index}`}
                                    className="product-link"
                                    to={`/ProdView/${product.proD_NAME}`}
                                    state={{ imgDirectory: imageDirectory, docsDirectory: docsDirectory, prod: product }}
                                >
                                    <div className="product" onClick={() => navToPage('/Products', 0)}>
                                        <img
                                            src={`${imageDirectory}/${product.imG_PATH}`}
                                            alt={product.proD_NAME}
                                        />
                                        <h2>{product.proD_NAME}</h2>
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>


                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <button className="all-products-button" onClick={() => { navigate(`/Products`); }}>All Products</button>
                </div>
            </div>
        </div>
    );
};

export default Home;
