import React, { useState } from 'react';
import "../css/prodView.css";
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import { FileEarmarkText, FileEarmarkPdf, Download, ArrowDownCircle, ArrowUpCircleFill } from 'react-bootstrap-icons';

const ProdContent = ({ description, documents, docsDir, about }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const drawings = null;
    const technicalData = null;

    const renderBenefits = () => {
        if (!description || description.length === 0) return null;

        const splitIndex = Math.ceil(description.length / 2);
        const leftColumn = description.slice(0, splitIndex);
        const rightColumn = description.slice(splitIndex);

        return (
            <div className="product-description-container">
                {description.length > 10 ? (
                    <div className="benefits-columns">
                        <ul className="bullet-list left-column">
                            {leftColumn.map((item, index) => (
                                <li style={{ marginLeft: '10px' }} key={index}>{item}</li>
                            ))}
                        </ul>
                        <ul className="bullet-list right-column">
                            {rightColumn.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <ul className="bullet-list">
                        {description.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                )}
            </div>
        );
    };


    const renderDocuments = () => {
        const style = {
            fontSize: '20px',
            marginTop: '10%'
        }
        if (documents != null) {
            const documentsContent = (
                <div className="documents-container">
                    {documents.map((document, index) => {
                        console.log(document);
                        let icon = <FileEarmarkText className='icon' />;
                        if (document.doC_PATH.includes("pdf")) {
                            icon = <FileEarmarkPdf className='icon' />;
                        } else if (document.doC_PATH.includes("exe")) {
                            return (
                                <a href={docsDir + '/' + document.doC_PATH} download  >
                                    <div key={index} >
                                        <Download className='icon' /> {document.doC_NAME}
                                    </div>
                                </a>
                            );
                        }
                        return (
                            <a href={docsDir + '/' + document.doC_PATH} className='docs-Button' target="_blank" rel="noopener noreferrer">
                                <div key={index} >

                                    {icon} {document.doC_NAME}

                                </div>
                            </a>

                        );
                    })}
                </div>
            );
            return documentsContent;
        } else {
            return null
        }
       

       
    }

    const renderAbout = () => {
        console.log(about)
        if (about != [] && about != null) {
            const content = (
                <div>
                    {Object.entries(about).map(([header, content]) => (
                        <div key={header}>
                            <h2>{header}</h2>
                            {console.log(content)}
                            {Array.isArray(content) ? (
                                <ul>
                                    {content.map((line, index) => (
                                        <li className="bullet-list" key={index}>{line}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{content}</p>
                            )}
                        </div>
                    ))}
                </div>
            );
            return content;
        } else {
            return null;
        }
        
    }

    const renderDrawings = () => {
       
        if (drawings != [] && drawings != null) {
            const content = (
                <div>
                    {Object.entries(about).map(([header, content]) => (
                        <div key={header}>
                            <h2>{header}</h2>
                            {console.log(content)}
                            {Array.isArray(content) ? (
                                <ul>
                                    {content.map((line, index) => (
                                        <li className="bullet-list" key={index}>{line}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{content}</p>
                            )}
                        </div>
                    ))}
                </div>
            );
            return content;
        } else {
            return null;
        }

    }

    const renderTechnicalData = () => {

        if (technicalData != [] && technicalData != null) {
            const content = (
                <div>
                    {Object.entries(about).map(([header, content]) => (
                        <div key={header}>
                            <h2>{header}</h2>
                            {console.log(content)}
                            {Array.isArray(content) ? (
                                <ul>
                                    {content.map((line, index) => (
                                        <li className="bullet-list" key={index}>{line}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{content}</p>
                            )}
                        </div>
                    ))}
                </div>
            );
            return content;
        } else {
            return null;
        }

    }

    const toggleSection = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const sections = [
        { title: 'About the product', content: renderAbout() },
        { title: 'Benefits', content: renderBenefits() },
        { title: 'Drawings', content: renderDrawings() },
        { title: 'Documents', content: renderDocuments() },
        { title: 'Technical Data', content: renderTechnicalData() },
    ];

    return (
        <div style={{ width: '100%' }}>
            <h2 style={{ fontSize: '3rem' }}>More product information</h2>
            {sections.map((section, index) => (
                section.content != null && (
                <div
                    key={index}
                    style={{
                        borderBottom: '1px solid #ccc',
                        marginBottom: '10px',
                        marginLeft: '1%',
                        position: 'relative', // Ensure dropdowns expand correctly
                    }}
                >
                    <div
                        onClick={() => toggleSection(index)}
                        className="prod-info-titles"
                    >
                        <span style={{ fontSize: '25px' }}>{section.title}</span>
                        <span onClick={() => handleClick(index)}
                            style={{ display: 'inline-block', transition: 'transform 0.3s ease', width:'3%' }}
                           
                        >
                                {activeIndex === index ? <ArrowUpCircleFill size="sm" style={{ color: 'red' }} /> : <ArrowDownCircle size="sm" />}
                        </span>
                    </div>
                    <div
                        className={`prod-info-content ${activeIndex === index ? 'open' : ''}`}
                    >
                        {activeIndex === index && (
                            <div style={{ padding: '10px 0' }}>
                                {section.content}
                            </div>
                        )}
                    </div>
                    </div>
                )
            ))}
        </div>
        
    );
};

export default ProdContent;
