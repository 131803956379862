import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Button,
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
    CardTitle,
} from 'reactstrap';
import "../css/main.css";
import IconInput from '../components/Shared/IconInput';
import { Search } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import  ProdView  from '../components/ProdView';
import { ArrowLeft } from 'react-bootstrap-icons';

const Products = () => {
    const { state } = useLocation();
    const { cat, products, selCat } = state;


    //const [products, setProducts] = useState(prod);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [content, setContent] = useState([]);
    const [filterBy, setFilterBy] = useState(0);
    const [filterList, setFilterList] = useState([]);
    const [showProductList, setShowProductList] = useState(true);
    const [selectedProdID, setSelectedProdID] = useState(null);
    const [imageDirectory, setImageDirectory] = useState("");
    const [imageDataList, setImageDataList] = useState([]);
    const [documents, setDocuments] = useState(null);
    const bearerToken = localStorage.getItem('bearerToken');

    useEffect(() => {
        console.log(products);
        setLoading(true);
       
        setContent(products);
        getDirectories();
        preSetCategory();
    }, []);

    //const getProductsList = async () => {
    //    try {
    //        const response = await axios.get('/Website/getProducts', {
    //            headers: { Authorization: `Bearer ${bearerToken}` },
    //        });
    //        setProducts(response.data);
    //        setContent(response.data);
    //    } catch (error) {
    //        console.error('Error fetching product list:', error);
    //    }
    //};

    const getDirectories = async () => {
        try {
            const response = await axios.get("/Website/GetDirectories", {
                headers: { Authorization: `Bearer ${bearerToken}` },
            });
            console.log(response.data)
            setImageDirectory(response.data.imgDirectory);
            setDocuments(response.data.docsDirectory);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching image directory:', error);
        }
    };

    const preSetCategory = () => {
        const catID = parseInt(selCat);
        setFilterBy(catID);

        let filteredList = filterList.length
            ? filterList
            : products;

        if (catID !== 0) {
            filteredList = filteredList.filter(
                (item) => item.caT_ID.includes(catID)
            );
        } else {
            filteredList = filterList.length ? filterList : products;
        }

        setContent(filteredList);
    };


    const handleCategoryChange = (e) => {
        const catID = parseInt(e.target.value);
        setFilterBy(catID);

        let filteredList = filterList.length
            ? filterList
            : products;

        if (catID !== 0) {
            filteredList = filteredList.filter(
                (item) => item.caT_ID.includes(catID)
            );
        } else {
            filteredList = filterList.length ? filterList : products;
        }

        setContent(filteredList);
    };

    const searchProducts = (text) => {
        const searchParam = ["proD_NAME"];
        let result;

        const filteredByText = products.filter((item) =>
            searchParam.some((param) =>
                item[param].toLowerCase().includes(text.toLowerCase())
            )
        );

        if (filterBy === 0) {
            result = filteredByText;
        } else {
            result = filteredByText.filter((item) =>
                item.caT_ID.includes(filterBy)
            );
        }

        setFilterList(result);
        setContent(result);
    };

    const productSelected = (prod) => {
        setSelectedProdID(prod);
        setShowProductList(false);
        //navigate(`/ProdView/${prod.proD_NAME}`);
    };

    const getDeviceImage = (imagePath) => {
        const imageData = imageDataList.find(
            (x) => x.fileName === imagePath
        );
        return imageData ? imageData.fileData : null;
    };

    const renderCardImage = async (imagePath) => {
        const imageData = await fetchImageData(imagePath);

        if (imageData) {
            return (
                <CardImg
                    className="card-image"
                    src={`data:image/jpeg;base64,${imageData.fileData}`}
                />
            );
        }
        return null;
    };

    const fetchImageData = (imagePath) => {
        return imageDataList.find((x) => x.fileName === imagePath) || null;
    };

    if (loading) {
        return (
            <Container style={{ textAlign: 'center', paddingTop: '10%' }}>
                <span className="loader"></span>
            </Container>
        );
    }

    return (
        <div>
            {showProductList ? (
                <Row style={{ marginTop: "20px" }}>
                    <Col sm="3" className="category-col">
                        <h4 className="filter-header">Filters</h4>
                        <div className="category-list">
                            <div className="category-option">
                                <input
                                    type="radio"
                                    id="allCategories"
                                    name="category"
                                    value="0"
                                    checked={filterBy === 0}
                                    onChange={handleCategoryChange}
                                />
                                <label htmlFor="allCategories">All Products</label>
                            </div>
                            {cat.map((category) => (
                                <div className="category-option" key={category.caT_ID}>
                                    <input
                                        type="radio"
                                        id={category.caT_ID}
                                        name="category"
                                        value={category.caT_ID}
                                        checked={filterBy === category.caT_ID}
                                        onChange={handleCategoryChange}
                                    />
                                    <label htmlFor={category.caT_ID}>{category.caT_NAME}</label>
                                </div>
                            ))}
                            <IconInput
                                placeholder="Search"
                                icon={<Search />}
                                name="NavSearchBar"
                                size="sm"
                                type="text"
                                autoComplete="search"
                                onChange={(e) => searchProducts(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col sm="9" className="centralize-cards">
                        <Row>
                            {content.map((product, index) => (
                                <Col sm="6" md="4" lg="3" key={index} className="card-col">
                                    <div
                                        style={{
                                            border: '1px solid black',
                                            borderRadius: '8px', // Rounded edges
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%', // Ensure consistent card height
                                            padding: '10px',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Link
                                            style={{
                                                color: 'black',
                                                textDecoration: 'none',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%', // Ensure the link fills the entire card
                                            }}
                                            to={`/ProdView/${product.proD_NAME}`}
                                            state={{ imgDirectory: imageDirectory, docsDirectory: documents, prod: product }}
                                        >
                                            {product.imG_PATH && (
                                                <div
                                                    className="image-container"
                                                    style={{
                                                        height: '200px', // Fixed image height
                                                        overflow: 'hidden', // Prevent image overflow
                                                        marginBottom: '10px', // Space between image and title
                                                    }}
                                                >
                                                    <img
                                                        src={`${imageDirectory}/${product.imG_PATH}`}
                                                        alt={product.proD_NAME}
                                                        style={{
                                                            objectFit: 'cover',
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div style={{ flexGrow: 1 }}>
                                                <h5 style={{ margin: '0' }}>{product.proD_NAME}</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            ) : (
                <div>
                    <Button
                        color="link"
                        className="back-button"
                        onClick={() => {
                            setSelectedProdID(null);
                            setShowProductList(true);
                        }}
                    >
                        <ArrowLeft className="back-icon" size={20} color="black" />
                        <span className="back-text">Back to Products</span>
                    </Button>
                    <ProdView prod={selectedProdID} />
                </div>
            )}
        </div>
    );
};

export default Products;
