import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import "../css/prodView.css";
import axios from 'axios';
import ProdContent from './ProdContent';

const ProdView = () => {
    const { productName } = useParams();
    const location = useLocation();

    const { imgDirectory, docsDirectory, prod } = location.state || {};

    const [isLoading, setIsLoading] = useState(false);
    const [selectedThumbnail, setSelectedThumbnail] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [sirenOption, setSirenOption] = useState("");
    const [gallery, setGallery] = useState([]);
    const [desc, setDesc] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [about, setAbout] = useState([]);
    useEffect(() => {
        console.log(prod + " " + productName)
        const fetchData = async () => {
            setIsLoading(true);
            await getGalleryFiles();
            await getProductFeatures();
            await getProductDocumentation();
            setIsLoading(false);
        };
        fetchData();
    }, []);




    const getGalleryFiles = async () => {
        try {
            const response = await axios.post(
                "/Website/GetGalleryImagesByPath",
                prod.proD_ID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                    },
                }
            );
            setGallery(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getProductFeatures = async () => {
        try {
            const response = await axios.post("/Website/GetDescriptionByProdID", prod.proD_ID, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            const arr = JSON.parse(response.data.desC_JSON);
            console.log(arr);
            setDesc(arr.list);
            setAbout(arr.about);

        } catch (error) {
            console.error(error);
        }
    };

    const getProductDocumentation = async () => {
        try {
            const response = await axios.post("/Website/GetDocumentsbyProdID", prod.proD_ID, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
                },
            });
            setDocuments(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    const handleThumbnailClick = (index) => {
        setSelectedThumbnail(index);
    };

    const handleIncrement = () => {   
        setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const handleDecrement = () => {
        setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
    };

    const handleSirenChange = (event) => {
        setSirenOption(event.target.value);
    };

   

    return (
        <div className="product-page">
            {isLoading ? (
                <div className="loading-container">
                    <span className="loader"></span>
                </div>
            ) : (
                <>
                    <div className="product-container">
                        <div className="product-image">
                            <div className="prodView-image-container">
                                <div className="slider" style={{ transform: `translateX(-${selectedThumbnail * 100}%)` }}>
                                    {gallery.map((image, index) => (
                                        <img
                                            key={index}
                                            src={`${imgDirectory}/${image.imG_PATH}`}
                                            alt={image.imG_ID}
                                            className="main-image"
                                        />
                                    ))}
                                </div>
                            </div>

                            <div className="thumbnail-container">
                                {gallery.map((image, index) => (
                                    <div style={{ width: '80px', height: '80px' }} key={index}>
                                        <img
                                            src={`${imgDirectory}/${image.imG_PATH}`}
                                            alt={image.imG_ID}
                                            className={`thumbnail ${selectedThumbnail === index ? 'selected' : ''}`}
                                            onClick={() => handleThumbnailClick(index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="product-details">
                            <h2>{productName}</h2>
                            <p>{prod.proD_DESC}</p>
                        </div>
                    </div>
                    <ProdContent description={desc} documents={documents} docsDir={docsDirectory} about={about} />
                </>
            )}
        </div>
    );

};

export default ProdView;
